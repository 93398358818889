import React from "react";
import styled, { ThemeConsumer } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { CONF } from "../conf";
import { Link } from "gatsby";
import { Theme } from "@Styles/Theme";

const MenuWrapper = styled.div`
  position: fixed;
  top: 50px;
  left: 50px;
  z-index: -1000;
  opacity: 0;
  transition: opacity 0.5s ease;

  &.menuVisible {
    opacity: 1;
    z-index: 60000;
  }
`;

const OpenWrapper = styled.div`

  width: auto;
  height: auto;
  display: flex;
  gap: 1rem;
  // flex-direction: row;
  // align-items: flex-start;

  img{
    height: 30px;
    width: 100px;
  }

  ul{
    list-Style-type: none;
    li{
      height: 2.8rem;
      width: fit-content;
      a{
        display: block
        width: 100%;
        height: 100%;
        color: ${(props) => (props.textIsRed ? Theme.colors.red : "#fff")};
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.8rem;
        font-style: italic;
        text-transform: uppercase;
        transition: all .1s ease-out;

        &:hover{
          font-weight: 600;
          text-decoration: none;          
        }
      }
      &.active a{
        font-size: 3rem;
        font-weight: 900;
      }
    }
    .separator{
      height: 1px;
      width: 50px;
      background-color: ${(props) =>
        props.textIsRed ? Theme.colors.red : "#fff"};
    }
  }
`;

export default function Menu({ location, visible, open, textIsRed }) {
  let dataImages = useStaticQuery(graphql`
    query MenuImages {
      eyes: file(relativePath: { eq: "logos/menu_eyes.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            originalName
            sizes
            src
          }
        }
      }
      blueLogo: file(relativePath: { eq: "logos/logo_loony_bleu.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            originalName
            sizes
            src
          }
        }
      }
    }
  `);

  function renderLogoPos() {
    switch (location) {
      case "home":
        return 0;
      case "skills":
        return 28;
      case "projects":
        return 56;
      case "contact":
        return 84;
    }
  }

  function renderOpen() {
    return (
      <OpenWrapper textIsRed={textIsRed}>
        <Img
          fluid={dataImages.eyes.childImageSharp.fluid}
          style={{
            width: 30 * dataImages.eyes.childImageSharp.fluid.aspectRatio,
            height: "30px",
            position: "relative",
            top: renderLogoPos(),
          }}
        />
        <ul>
          {CONF.MENU.items.map((item, idx) => (
            <>
              <li
                className={location === item.link && "active"}
                key={`${item.link}${idx}`}
              >
                {location === item.link ? (
                  <a href="javascript:void(0)">{item.name}</a>
                ) : (
                  <Link to={`/${item.link}`}>{item.name}</Link>
                )}
              </li>
              {idx < CONF.MENU.items.length - 1 && (
                <div className="separator"></div>
              )}
            </>
          ))}
        </ul>
      </OpenWrapper>
    );
  }

  function renderClose() {
    return <></>;
  }

  return (
    <MenuWrapper id="navMenuWrapper" className="menuVisible">
      {visible ? renderOpen() : renderClose()}
    </MenuWrapper>
  );
}
