import React from "react"
import Menu from "@Components/Menu"
import '@Styles/main.scss'

export default function Layout({
  location,
  children,
  menuVisible,
  menuOpen,
  textIsRed
}) {

  return (
    <>
      <Menu location={location} visible={menuVisible} open={menuOpen} textIsRed={textIsRed} />
      {children}
    </>
  )
}