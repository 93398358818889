export const CONF = {};

CONF.SKILLS = [
  {
    name: "studioCrea",
    services: [
      "Identité de marque",
      "Logotype",
      "Charte graphique",
      "Webdesign",
    ],
  },
  {
    name: "branding",
    services: [
      "Conseil en stratégie",
      "Edition",
      "Relations publiques et medias",
      "Publicité",
    ],
  },
  {
    name: "event",
    services: [
      "Stratégie",
      "Gestion de projet",
      "Direction artistique de l'evenement\n( web/print )",
    ],
  },
  {
    name: "digital",
    services: ["Création et développement de sites web"],
  },
];

CONF.CONTACT_TEXT =
  "Pas d'appel pas d'feeling!pas d'feeling pas d'contrat!pas d'contrat pas d'créa!pas d'créa...!!pas d'créa. ”";

CONF.PROJECTS = [
  {
    title: "PISCO&CO.",
    args: [
      "Stratégie de communication",
      "Conception et rédaction",
      "Identité visuelle",
      "Web design",
      "Création de site internet",
    ],
    img: "pisco.png",
  },
  {
    title: "SOHA",
    args: [
      "Stratégie de communication",
      "Développement de produits",
      "Identité visuelle",
      "Conception et rédaction",
    ],
    img: "soha.png",
  },
  {
    title: "HOKI VESTA",
    args: [
      "Conception et rédaction  / naming",
      "Identité visuelle",
      "Direction artistique",
    ],
    img: "hoki_vesta.png",
  },
  {
    title: "ST SO",
    args: ["Illustration", "Recherche graphique"],
    img: "st_so_renoir.png",
  },
  {
    title: "GALA BOXE",
    args: ["Direction artistique", "Identité visuelle", "Shooting photo"],
    img: "direct_droit.png",
  },
  {
    title: "LA LUCK",
    args: ["Conception et rédaction"],
    img: "la_luck.png",
  },
  {
    title: "DOUR",
    args: ["Production événementielle", "Gestion des accueils artistes"],
    img: "dour.png",
  },
  {
    title: "MONDE EN FANFARE",
    args: ["Identité visuelle", "Conception visuelle d’affiches"],
    img: "fanfare.png",
  },
  {
    title: "VOITURE",
    args: ["Digital painting"],
    img: "cars.png",
  },
  {
    title: "SHOPPING",
    args: ["Identité visuelle", "Conception de visuels promotionnels"],
    img: "shopping.png",
  },
  {
    title: "AIR +",
    args: [
      "Direction artistique",
      "Identité visuelle",
      "Shooting photo",
      "Campagne de lancement print",
    ],
    img: "r_plus.png",
  },
  {
    title: "Nuits Electriques",
    args: [
      "Production événementielle",
      "Gestion de projet",
      "Direction de communication",
      "Relations médias et publiques",
    ],
    img: "nuits_electriques.png",
  },
  {
    title: "MUSEE",
    args: ["Direction artistique", "Shooting photo", "Composition visuelle"],
    img: "musee_histoire_naturelle.png",
  },
  {
    title: "SOLEIL D’OR",
    args: ["Identité visuelle", "Conception visuelle"],
    img: "soleil_dor.png",
  },
  {
    title: "GHOST",
    args: ["Identité visuelle logotype", "Illustration"],
    img: "ghost.png",
  },
  {
    title: "SNCF",
    args: ["Identité visuelle", "Direction artistique"],
    img: "sncf.png",
  },
  {
    title: "FEMME VOLANT",
    args: ["Digital painting"],
    img: "gun.png",
  },
];

CONF.MENU = {
  items: [
    {
      name: "Home",
      link: "",
    },
    {
      name: "Skills",
      link: "skills",
    },
    {
      name: "Projets",
      link: "projects",
    },
    {
      name: "Contact",
      link: "contact",
    },
  ],
};

CONF.LEGALS = [
  {
    title: "Présentation du site.",
    content: `
      En vertu de l’article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site loonny-agency.com l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
      <br/>
      <b>Propriétaires</b> : Loony Agency  - Flore GUFFROY & Samuel BEEKHARRY, 54 résidence les Maisons Landaises, 33680 LACANAU
      <br/>
      <b>Créateur</b> : Loony Agency
      <br/>
      <b>Responsable publication</b> : Flore Guffroy - Loony Agency – contact@loony-agency.com
      <br/>
      <b>Hébergeur</b> : https://www.netlify.com/
    `,
  },
  {
    title:
      "Conditions générales d’utilisation du site et des services proposés.",
    content: `
      L’utilisation du site Loony-agency.com implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site Loony-agency.com sont donc invités à les consulter de manière régulière.
      <br/>
      Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Flore GUFFROY, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.
      <br/>
      Le site Loony-agency.com est mis à jour régulièrement par Flore GUFFROY. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.
    `,
  },
  {
    title: "Description des services fournis.",
    content: `
      Le site Loony-agency.com a pour objet de fournir une information concernant l’ensemble des activités de la société.
      <br/>
      Flore GUFFROY s’efforce de fournir sur le site Loony-agency.com des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
      <br/>
      Tous les informations indiquées sur le site Loony-agency.com sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le Loony-agency.com ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.    `,
  },
  {
    title: "Limitations contractuelles sur les données techniques.",
    content: `
      Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour.
    `,
  },
  {
    title: "Propriété intellectuelle et contrefaçons.",
    content: `
      Loony Agency – Flore Guffroy & Samuel Beekharry est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels.
      <br/>
      Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Flore GUFFROY.
      <br/>
      Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie.
    `,
  },
  {
    title: "Limitations de responsabilité.",
    content: `
      Loony Agency ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site Loony-agency.com, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité.
      <br/>
      Loony Agency ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site Loony-agency.com.
    `,
  },
  {
    title: "Gestion des données personnelles.",
    content: `
      En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
      <br/>
      A l’occasion de l’utilisation du site Loony-agency.com, peuvent être recueillies : l’URL des liens par l’intermédiaire desquels l’utilisateur a accédé au site Loony-agency.com, le fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de l’utilisateur.
      <br/>
      l’utilisateur que pour le besoin de certains services proposés par le site Loony-agency.com.
      <br/><br/>
      Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit être envoyée.
      <br/><br/>
      Aucune information personnelle de l’utilisateur du site Loony-agency.com publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de Loony-agency.com et de ses droits permettrait la transmission des dites informations à l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l’utilisateur du site Loony-agency.com.
      Le site n’est pas déclaré à la CNIL car il ne recueille pas d’informations personnelles.
      Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.
    `,
  },
  {
    title: "Liens hypertextes et cookies.",
    content: `
      La navigation sur le site Loony-agency.com est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation.
      <br/>
      Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des cookies :
      <br/>
      Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite) / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.
      Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller dans l’onglet Options. Cliquer sur l’onglet Vie privée.
      <br/>
      Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés pour l’historique. Enfin décochez-la pour désactiver les cookies.
      <br/>
      Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur Paramètres de contenu. Dans la section « Cookies », vous pouvez bloquer les cookies.
      <br/>
      Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section « Confidentialité », cliquez sur préférences. Dans l’onglet « Confidentialité », vous pouvez bloquer les cookies.  
    `,
  },
  {
    title: "Droit applicable et attribution de juridiction.",
    content: `
      Tout litige en relation avec l’utilisation du site Loony-agency.com est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.
    `,
  },
  {
    title: "Les principales lois concernées.",
    content: `
      Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l’informatique, aux fichiers et aux libertés.
      <br/>
      Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.
    `,
  },
  {
    title: "Lexique.",
    content: `
      Utilisateur : Internaute se connectant, utilisant le site susnommé.
      <br/>
      Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit, directement ou non, l’identification des personnes physiques auxquelles elles s’appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
    `,
  },
];
