////////////////////////////////////////////////////////////////////
////////////////////////// SPLASH SCREEN ///////////////////////////
////////////////////////////////////////////////////////////////////

export const simpleFlickering = ({
  elements, // Array of elements
  delta, // Speed Ratio
  ratio // minimum stay display time
}) => {

  if (elements.length && delta) {
    const showHide = async () => {
      const node = elements[Math.floor(Math.random() * Math.floor(elements.length))]
      node.style.opacity = 1
      setTimeout(() => {
        node.style.opacity = 0
        setTimeout(showHide, Math.round(Math.random() * delta * 100))
      }, Math.round((Math.random() + ratio) * 1000))
    }
    showHide()
  }
}

////////////////////////////////////////////////////////////////////
////////////////////////// SKILLS SCREEN ///////////////////////////
////////////////////////////////////////////////////////////////////

export const multipleFlickers = ({
  element, // element on which add flicker effect
  maxTimes, // max times the element will flicker in a row
  flickerTimeRatio, // ratio for max flicker time,
  minRestart, // min value the flicker will restart
  maxRestart // max value the flicker will restart
}) => {

  const times = maxTimes > 0 ? Math.round(Math.random() * maxTimes) : 1

  let passed = 0

  const showHide = () => {
    passed++

    if (passed >= times) {
      return setTimeout(
        () => multipleFlickers({
          element,
          maxTimes,
          flickerTimeRatio,
          minRestart,
          maxRestart
        }),
        Math.random() * maxRestart
      )
    }

    element.style.opacity = 0

    setTimeout(() => {
      element.style.opacity = 1
      setTimeout(showHide, flickerTimeRatio)
    }, flickerTimeRatio)

  }

  showHide()
}


export const UID = () => {
  const n = Math.floor(Math.random() * 11)
  const k = Math.floor(Math.random() * 1000000)
  return String.fromCharCode(n) + k
}

export function mapDataImages(dataImages) {
  return dataImages.allFile.edges.map(edge => ({
    name: edge.node.childImageSharp.fluid.originalName,
    fluid: edge.node.childImageSharp.fluid
  }))
}

export function retrieveImageFluid(dataImages, imageName, src = false) {
  if (src) {
    return dataImages.find(img => img.name === imageName).fluid.src
  }
  return dataImages.find(img => img.name === imageName).fluid
}

export function autoTypeText(parentNode, str) {
  const arr = str.split('')
  let i = 0
  let isPaused = false
  let interval = setInterval(() => {
    if (!isPaused) {
      if (arr[i] === "!") {
        isPaused = true
        parentNode.appendChild(document.createElement('br'))
        i++
        return setTimeout(() => isPaused = false, 500)
      }
      parentNode.innerHTML += arr[i]
      i++
      if (i >= arr.length) {
        clearInterval(interval)
      }
    }
  }, 50)
}



